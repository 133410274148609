import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`Usually we use it for a large amount of text, perhaps in response to an open-ended question, qualitative feedback or notes on a game, opt for the text area over an text field.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/area-1.svg",
      "alt": "textarea guideline anatomy"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Label :`}</strong>{` indicates what you want the user to input in the text area.`}</li>
      <li parentName="ol"><strong parentName="li">{`Placeholder :`}</strong>{` a short hint that describes the expected value of an input.`}</li>
      <li parentName="ol"><strong parentName="li">{`Caption (optional) :`}</strong>{` provides contextual assistance like helper text, in any area where the user might need additional information to provide the right input.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`Text areas are fields that span multiple lines. We recommend using text area fields when the requested input text is expected to span multiple lines. Text areas are commonly used in forms.`}</p>
    <p><strong parentName="p">{`Common places textarea appear:`}</strong>{` Forms`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li> To collect longer answers from users.</li>
      <li> To gather detail information with guidance on what to enter. </li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li> For short and simple user input. </li>
      <li> For longer responses that users can upload. </li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`For the accessibility and touch target of users You must label text areas. Placeholder text is not a suitable substitute for a label, as it disappears when users click inside the text area.
Labels must be aligned above the textarea they refer to. They should be short, direct and written in sentence case. Do not use colons at the end of labels.`}</p>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : With label" caption="You should label text area to make sure user know what to input in there" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/area-2.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : Without label" caption="Placeholder text is not a suitable substitute for a label, as it disappears when users click inside the text area" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/area-3.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Text Area :`}</p>
    <ul>
      <li parentName="ul">{`Use lables , they’re especially important for people who don’t see other visual cues. But they also help everyone know exactly what to enter`}</li>
      <li parentName="ul">{`Use help and error messages or indicatorUse help messages to guide users before they enter anything and clear calm error messages when there’s a problem with what they entered.`}</li>
      <li parentName="ul">{`Show a hint in a text area to help communicate its purpose.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
  <DoDont type="do" captionTitle="Do : For long text input" caption="use a text area when the requested input text is expected to span multiple lines" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/area-4.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't : For short text input" caption="use a text area when the requested input text could be answered in short text" aspectRatio="16:9" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/textarea/area-5.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      